import { render, staticRenderFns } from "./inviteShip.vue?vue&type=template&id=3c8c5838"
import script from "./inviteShip.vue?vue&type=script&lang=js"
export * from "./inviteShip.vue?vue&type=script&lang=js"
import style0 from "./inviteShip.vue?vue&type=style&index=0&id=3c8c5838&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\user\\Desktop\\Bunkerchain\\portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c8c5838')) {
      api.createRecord('3c8c5838', component.options)
    } else {
      api.reload('3c8c5838', component.options)
    }
    module.hot.accept("./inviteShip.vue?vue&type=template&id=3c8c5838", function () {
      api.rerender('3c8c5838', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/emailViews/inviteShip.vue"
export default component.exports